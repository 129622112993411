/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Link, useLocation } from "react-router-dom";
import { Dropdown1 } from "../../../_metronic/partials";
import { useAuth } from "../auth";
import {
  countAllAssociatedLicence,
  countAllUnassociatedLicence,
  countStudents
} from "../../api";

const ProfileHeader: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();

  const [studentCount, setStudentCount] = useState(0);
  const [assignedLicenceCount, setAssignedLicenceCount] = useState(0);
  const [unAssignedLicenceCount, setUnassignedLicenceCount] = useState(0);

  useEffect(() => {
    countStudents({ id: currentUser ? currentUser.universityId : "" }).then(
      (res) => {
        setStudentCount(res.data);
      }
    );

    countAllAssociatedLicence({
      id: currentUser ? currentUser.universityId : ""
    }).then((res) => {
      setAssignedLicenceCount(res.data);
    });

    countAllUnassociatedLicence({
      id: currentUser ? currentUser.universityId : ""
    }).then((res) => {
      setUnassignedLicenceCount(res.data);
    });
  });

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src="https://formrecognizer12345.blob.core.windows.net/entigrityhandout/WLU%20logo.png"
                alt="University"
              />
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >
                    {currentUser
                      ? currentUser.universityName
                      : "University Name"}
                  </a>
                  <a href="#">
                    <KTIcon iconName="verify" className="fs-1 text-primary" />
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                  >
                    <KTIcon iconName="geolocation" className="fs-4 me-1" />
                    London, Ontario Canada N5Y 5R6
                  </a>
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                  >
                    <KTIcon iconName="sms" className="fs-4 me-1" />
                    {currentUser ? currentUser.email : "mail@university.com"}
                  </a>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-end flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  <Link to={"/students"}>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">{studentCount}</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">Students</div>
                    </div>
                  </Link>
                  <Link to={"/licences"}>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {assignedLicenceCount + unAssignedLicenceCount}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">Licences</div>
                    </div>
                  </Link>
                  <Link to={"/students"}>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {assignedLicenceCount}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        Assosiated
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className='d-flex my-4'>
                {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a> */}
                <a
                  href='https://safera.ai'
                  target="_blank"
                  className='btn btn-sm btn-primary me-3'
                >
                  Test Lab
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export { ProfileHeader };
