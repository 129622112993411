/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login, getUserByToken, resetPassword } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
// import { login } from "../../../api";
import { setAuth } from "../core/AuthHelpers";

const loginSchema = Yup.object().shape({
  otp: Yup.string()
    .min(4, "Minimum 3 symbols")
    .max(4, "Maximum 50 symbols")
    .required("OTP is required"),
  new_password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required")
});

const initialValues = {
  otp: "",
  new_password: ""
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(values.otp, values.new_password)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            setTimeout(() => {
              navigate('/auth')
            }, 5000);
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">
          Safera Reset Password
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>For New Student - <Link to="/auth/registration" className="link-primary">
          Sign Up
        </Link></div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        <></>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">OTP</label>
        <input
          placeholder="OTP"
          {...formik.getFieldProps("otp")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.otp && formik.errors.otp },
            {
              "is-valid": formik.touched.otp && !formik.errors.otp
            }
          )}
          name="otp"
          autoComplete="off"
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.otp}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Password
        </label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("new_password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.new_password && formik.errors.new_password
            },
            {
              "is-valid": formik.touched.new_password && !formik.errors.new_password
            }
          )}
        />
        {formik.touched.new_password && formik.errors.new_password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.new_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-success"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
