/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Link, useLocation } from "react-router-dom";
import { Dropdown1 } from "../../../_metronic/partials";
import { useAuth } from "../auth";
import {
  countAllAssociatedLicence,
  countAllUnassociatedLicence,
  countStudents
} from "../../api";

const ProfileStats: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [studentCount, setStudentCount] = useState(0);
  const [assignedLicenceCount, setAssignedLicenceCount] = useState(0);
  const [unAssignedLicenceCount, setUnassignedLicenceCount] = useState(0);

  useEffect(() => {
    countStudents({ id: currentUser ? currentUser.universityId : "" }).then(
      (res) => {
        setStudentCount(res.data);
      }
    );

    countAllAssociatedLicence({
      id: currentUser ? currentUser.universityId : ""
    }).then((res) => {
      setAssignedLicenceCount(res.data);
    });

    countAllUnassociatedLicence({
      id: currentUser ? currentUser.universityId : ""
    }).then((res) => {
      setUnassignedLicenceCount(res.data);
    });
  }, []);

  return (
    <div className="card mb-10">
      <div className="card-body d-flex align-items-center py-8">
        <div className="d-flex h-80px w-80px flex-shrink-0 flex-center position-relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5"
          >
            <path
              fill="currentColor"
              d="M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z"
            ></path>
          </svg>
          <KTIcon
            iconName="wrench"
            className="fs-2x fs-lg-3x text-primary position-absolute"
          />
        </div>

        <div className="ms-6">
          <div className="d-flex flex-wrap flex-stack">
            <div className="d-flex flex-column flex-grow-1 pe-8">
              <div className="d-flex flex-wrap">
                <Link to={"/students"}>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">{studentCount}</div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">Students</div>
                  </div>
                </Link>

                <Link to={"/licences"}>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {assignedLicenceCount + unAssignedLicenceCount}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">Licences</div>
                  </div>
                </Link>

                <Link to={"/students"}>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {assignedLicenceCount}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">Assosiated</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProfileStats };
