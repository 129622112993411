/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import {
  getLayoutFromLocalStorage,
  ILayout,
  LayoutSetup,
  PageTitle,
  PageLink,
} from '../../../_metronic/layout/core'
import { AssosiateStudentModal } from '../../../_metronic/partials/modals/assosiate-student/AssosiateStudentModal'
import { KTIcon } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import {
  createAzureResources,
  getAllAssociatedStudents,
  getAllUnassociatedLicence,
  getAllUnassociatedStudents,
  licenceAssociation,
  registerWordpressUser,
  studentRegister,
} from '../../api'
import { useAuth } from '../../modules/auth'
import { ProfileStats } from '../../modules/profile/ProfileStats'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface DataUnassosiatedObject {
  studentId: string,
  studentName: string,
  studentEmail: string,
  universityId: string,
  licenceStatus: string,
  createdAt: string,
  updatedAt: string,
}

const StudentPage: React.FC = () => {
  const { currentUser } = useAuth()

  const [tab, setTab] = useState('assosiated')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSet, setDataSet] = useState('')
  const [searchStudentName, setSearchStudentName] = useState('')
  const [assosiatingMail, setAssosiatingMail] = useState('')

  const [newStudent, setNewStudent] = useState({
    studentId: '',
    studentName: '',
    email: '',
    universityId: currentUser ? currentUser.universityId : '',
    userType: 'university-student',
    password: ''
  })

  const [data, setData] = useState([
    {
      licenceNumber: '',
      associatedStudentName: '',
      associatedStudentEmail: '',
      Status: '',
      universityId: '',
      studentId: '',
      licenceUserName: '',
      licencePassword: '',
      createdAt: '',
      updatedAt: '',
    },
  ])

  const [dataUnassosiated, setDataUnassosiated] = useState<DataUnassosiatedObject[]>([])

  const [licenceUnassosiated, setLicenceUnassosiated] = useState([
    {
      Status: 'Not-Assigned',
      universityId: currentUser ? currentUser.universityId : '',
      licenceNumber: '',
      licenceUserName: '',
      licencePassword: '',
    },
  ])

  const [currStudent, setCurrStudent] = useState({
    universityId: '',
    studentName: '',
    studentEmail: '',
    licenceUserName: '',
  })

  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
      window.location.reload()
    } catch (error) {
      setConfig(getLayoutFromLocalStorage())
      setConfigLoading(false)
    }
  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayoutFromLocalStorage())
      setResetLoading(false)
    }, 1000)
  }

  const generateAlphaNumericPassword = () => {
    const alphanumericChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const specialChars = '!@#$%^&*<>?';
    const allChars = alphanumericChars + specialChars;

    let password = '';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars.charAt(randomIndex);
    }

    setNewStudent({ ...newStudent, password: password });
  }

  useEffect(() => {
    getAllAssociatedStudents({ id: currentUser ? currentUser.universityId : '' }).then((res) => {
      setData(res.data)
    })

    getAllUnassociatedStudents({ id: currentUser ? currentUser.universityId : '' }).then((res) => {
      setDataUnassosiated(res.data)
    })

    getAllUnassociatedLicence({ id: currentUser ? currentUser.universityId : '' }).then((res) => {
      setLicenceUnassosiated(res.data)
    })
  }, [tab])

  const handleAssignLicence = () => {
    setAssosiatingMail(currStudent.studentEmail)
    licenceAssociation(currStudent).then((res) => {
      // const userName = licenceUnassosiated.find(obj => obj.licenceUserName == currStudent.licenceUserName)?.licenceUserName
      // const password = licenceUnassosiated.find(obj => obj.licenceUserName == currStudent.licenceUserName)?.licencePassword
      if (res.status) {
        getAllAssociatedStudents({ id: currentUser ? currentUser.universityId : '' }).then((res) => {
          setData(res.data)
        })
        setTab('assosiated')
      }
      alert(res.message)
      setAssosiatingMail('')
      setCurrStudent({
        universityId: '',
        studentName: '',
        studentEmail: '',
        licenceUserName: '',
      });
    }).catch((e) => {
      setAssosiatingMail('')
      alert("Something went wrong. Please try again later")
    })
  }

  const handleAddNewStudent = () => {
    setLoading(true)
    studentRegister(newStudent)
      .then((res) => {
        if (res.status) {
          getAllUnassociatedStudents({ id: currentUser ? currentUser.universityId : '' }).then(
            (res) => {
              setDataUnassosiated(res.data)
              setLoading(false)
            }
          )
          setTab('unassosiated')
        }
        alert(res.message)
        setLoading(false)
        // remove classname from div using js
        const div = window.document.getElementById('kt_modal_assosiate_student')
        div ? div.classList.remove('show') : <></>

        // window.location.reload();
      })
      .catch((err) => {
        console.log(err.data)
        setLoading(false)
      })
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSearchStudent = () => {
    // searchStudent({studentEmail: searchStudentName}).then((res) => {
    //   setLicenceUnassosiated(res.data);
    // })
  }

  const handleResourceCreation = (e: any, username: string, email: string, randomPassword: string) => {
    e.preventDefault()
    createAzureResources({ 'username': username, 'email': email, 'password': randomPassword }).then((res) => {
      console.log(res);
    })
  }

  const handleResourceRelease = (e: any, username: string, email: string, randomPassword: string) => {
    e.preventDefault()
    createAzureResources({ 'username': username, 'email': email, 'password': randomPassword }).then((res) => {
      console.log(res);
    })
  }

  return (
    <div>
      {/* Modal */}
      <PageTitle breadcrumbs={profileBreadCrumbs}>Students</PageTitle>

      <ProfileStats />

      {/* assosiation modal */}
      <div className='modal fade' id='kt_modal_assosiate_student' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-between'>
              <h2>Assosiate Safera User ID to University Student</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <hr />
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Student Name
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    disabled={true}
                    className='form-control form-control-lg form-control-solid'
                    placeholder={currStudent.studentName}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Student Email
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    disabled={true}
                    className='form-control form-control-lg form-control-solid'
                    placeholder={currStudent.studentEmail}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Un-assigned Lincence
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={currStudent.licenceUserName}
                    onChange={(e) =>
                      setCurrStudent({
                        ...currStudent,
                        licenceUserName: e.target.value,
                      })
                    }
                  >
                    <option value=''>Select a Licence...</option>
                    {licenceUnassosiated.map((licence) => (
                      <option value={licence.licenceUserName}>{licence.licenceUserName}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              {/* <button className="btn btn-success">Confirm</button> */}
              <button
                className='btn btn-success'
                onClick={handleAssignLicence}
                data-bs-dismiss='modal'
              >
                Associate
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add student modal */}
      <div className='modal fade' id='kt_modal_add_student' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-between'>
              <h2>Add New Student</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <hr />
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              {/* <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    University Student ID
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      value={newStudent.studentId}
                      onChange={(e) =>
                        setNewStudent({
                          ...newStudent,
                          studentId: e.target.value
                        })
                      }
                    />
                  </div>
                </div> */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Student Name
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={newStudent.studentName}
                    onChange={(e) =>
                      setNewStudent({
                        ...newStudent,
                        studentName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Student Email
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    value={newStudent.email}
                    onChange={(e) =>
                      setNewStudent({
                        ...newStudent,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Student Password
                </label>

                <div className='col-lg-7 fv-row'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='form-control form-control-lg form-control-solid'
                    value={newStudent.password}
                    onChange={(e) =>
                      setNewStudent({
                        ...newStudent,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-lg-1 fv-row'>
                  <button className='btn btn-sm' onClick={handleTogglePassword}>
                    {showPassword ? <KTIcon iconName='eye-slash' className='fs-1' /> : <KTIcon iconName='eye' className='fs-1 text-success' />}
                  </button>
                </div>
              </div> */}
            </div>
            <div className='modal-footer'>
              {/* <button className="btn btn-success">Confirm</button> */}
              <button
                className='btn btn-success'
                onClick={handleAddNewStudent}
                data-bs-dismiss='modal'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
            role='tablist'
          >
            {/* assosiated tab btn */}
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'assosiated',
                })}
                onClick={() => setTab('assosiated')}
                role='tab'
                style={
                  tab == "assosiated"
                    ? { borderBottom: "4px solid #4d39e9" }
                    : { borderBottom: "" }
                }
              >
                Assosiated Students
              </a>
            </li>

            {/* unassosiated tab btn */}
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'unassosiated',
                })}
                onClick={() => setTab('unassosiated')}
                role='tab'
                style={
                  tab == "unassosiated"
                    ? { borderBottom: "4px solid #4d39e9" }
                    : { borderBottom: "" }
                }
              >
                Unassosiated Students
              </a>
            </li>
          </ul>
        </div>

        <form className='form'>
          <div className='card-body'>
            <div className='tab-content pt-3'>
              {/* assosiated tab view */}
              <div className={clsx('tab-pane', { active: tab === 'assosiated' })}>
                {/* <UsersPage /> */}
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-center'
                  role='table'
                >
                  <thead>
                    <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      {/* <th role='columnheader' className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='false'
                            data-kt-check-target='#kt_table_users .form-check-input'
                          />
                        </div>
                      </th> */}
                      {/* <th role='columnheader' className='min-w-125px' style={{cursor: 'pointer'}}>
                        Student Name
                      </th> */}
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Associated Email
                      </th>
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Licence UserName
                      </th>
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Licence Password
                      </th>
                      {/* <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Action
                      </th> */}

                      {/* <th
                    role="columnheader"
                    className="text-end min-w-100px"
                    style={{ cursor: "pointer" }}
                  >
                    Actions
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                    {data.map((item) => (
                      <tr role='row'>
                        {/* <td role='cell' className='text-center'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              data-kt-check='false'
                              data-kt-check-target='#kt_table_users .form-check-input'
                            />
                          </div>
                        </td> */}
                        {/* <td role='cell' className=''>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                              <a href='#'></a>
                            </div>
                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                                {item.associatedStudentName}
                              </a>
                            </div>
                          </div>
                        </td> */}
                        <td role='cell' className=''>
                          {item.associatedStudentEmail}
                        </td>
                        <td role='cell' className=''>
                          <div className='badge badge-light fw-bolder'>{item.licenceUserName}</div>
                        </td>
                        <td role='cell' className=''>
                          {item.licencePassword}
                        </td>
                        {/* <td role='cell' className=''>
                          <button
                            className='btn btn-sm btn-success mx-2'
                            onClick={(e) => { handleResourceCreation(e, item.licenceUserName, item.associatedStudentEmail, item.licencePassword) }}>
                            Create Azure Resource
                          </button>
                          <button
                            className='btn btn-sm btn-danger'
                            onClick={(e) => { handleResourceCreation(e, item.licenceUserName, item.associatedStudentEmail, item.licencePassword) }}>
                            Release Azure Resource
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* unassosiated tab view */}
              <div className={clsx('tab-pane', { active: tab === 'unassosiated' })}>
                {/* <UsersPage /> */}
                <div className='card-header border-0 pt-6'>
                  <div className='card-title'></div>
                  <div className='card-toolbar'>
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                      <button
                        type='button'
                        className='btn btn-success'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_add_student'
                        onClick={generateAlphaNumericPassword}
                      >
                        {loading ? (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>) :
                          <><i className='ki-duotone ki-plus fs-2'></i>Add Student</>
                        }
                      </button>
                    </div>
                  </div>
                </div>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-center'
                  role='table'
                >
                  <thead>
                    <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      {/* <th role='columnheader' className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='false'
                            data-kt-check-target='#kt_table_users .form-check-input'
                          />
                        </div>
                      </th> */}
                      <th role='columnheader' className='min-w-125px' style={{ cursor: 'pointer' }}>
                        Student Name{' '}
                      </th>
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Student Email
                      </th>
                      {/* <th
                        role="columnheader"
                        className="min-w-125px text-center"
                        style={{ cursor: "pointer" }}
                      >
                        University
                      </th> */}
                      <th
                        role='columnheader'
                        className='min-w-125px d-flex justify-content-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Action
                      </th>
                      {/* <th
                    role="columnheader"
                    className="text-end min-w-100px"
                    style={{ cursor: "pointer" }}
                  >
                    Actions
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                    {dataUnassosiated.length > 0 && dataUnassosiated.map((item) => (
                      <tr role='row'>
                        {/* <td role='cell' className='text-center'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              data-kt-check='false'
                              data-kt-check-target='#kt_table_users .form-check-input'
                            />
                          </div>
                        </td> */}
                        <td role='cell' className=''>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                              <a href='#'></a>
                            </div>
                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                                {item.studentName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td role='cell' className=''>
                          {item.studentEmail}
                        </td>
                        {/* <td role="cell" className="">
                          <div className="badge badge-light fw-bolder">
                            {item.universityId}
                          </div>
                        </td> */}
                        <td role='cell' className='d-flex justify-content-center'>
                          {/* <button className="btn btn-sm fw-bold btn-success" onClick={(e) => {setShow(true)}}>
                            Assosiate Licence
                          </button> */}
                          <a
                            href='#'
                            className='btn btn-sm btn-success px-6'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_assosiate_student'
                            onClick={(e) =>
                              setCurrStudent({
                                ...currStudent,
                                universityId: item.universityId,
                                studentName: item.studentName,
                                studentEmail: item.studentEmail,
                              })
                            }
                          >
                            {assosiatingMail == item.studentEmail ? (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>) :
                              <><i className='ki-duotone ki-plus fs-2'></i>Assosiate Student</>
                            }
                            {/* Assosiate Student */}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StudentPage