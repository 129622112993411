import axios from 'axios'
// const API_URL = 'http://137.184.53.243:8000'

// const API_URL = "https://univadmin.safera.ai:5000";
const API_URL = process.env.REACT_APP_API_URL

export const getAllUniversity = async () => {
  const {data} = await axios.post(`${API_URL}/getAllUniversities/`)
  return data
}

export const getAllSuperAdminStats = async () => {
  const {data} = await axios.post(`${API_URL}/adminStats/`)
  return data
}

export const getAllAssociatedStudents = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/getAllAssociatedStudents/`, {universityId: id})
  return data
}

export const getAllUniversityStats = async ({id}) => {
  const {data} = await axios.get(`${API_URL}/getAllUniversityStats/`, {universityId: id})
  return data
}

export const getAllUnassociatedStudents = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/getAllUnassociatedStudent/`,{universityId: id})
  return data
}

export const getAllUnassociatedLicence = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/geAllUnassociatedLicence/`, {universityId: id})
  return data
}

export const countStudents = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/countUniversityStudents/`, {universityId: id})
  return data
}

export const countAllAssociatedLicence = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/countAllAssociatedLicence/`, {universityId: id})
  return data
}

export const countAllUnassociatedLicence = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/countAllUnassociatedLicence/`, {universityId: id})
  return data
}

export const getAllLicences = async ({id}) => {
  const {data} = await axios.post(`${API_URL}/getAllLicences/`, {
    universityId: id,
  })
  return data
}

export const licenceAssociation = async (query) => {
  const {data} = await axios.patch(`${API_URL}/licenceAssociation/`, query)
  return data
}

// export const register = async (admin) => {
//   const {data} = await axios.post(`${API_URL}/userRegister`, admin)
//   return data
// }

export const register = async (admin) => {
  const {data} = await axios.post(`${API_URL}/authRegister/`, admin)
  return data
}

export const registerWordpressUser = async (admin) => {
  const {data} = await axios.post(`${API_URL}/createWordPressUser/`, admin)
  return data
}

export const registerUnievrsity = async (admin) => {
  const {data} = await axios.post(`${API_URL}/authRegister/`, admin)
  return data
}

export const login = async (admin) => {
  const {data} = await axios.post(`${API_URL}/login`, admin)
  return data
}

export const getUserByToken = async (token) => {
  const {data} = await axios.post(`${API_URL}/getUserByToken`, token)
  return data
}

export const studentRegister = async (student) => {
  const {data} = await axios.post(`${API_URL}/authRegister/`, student)
  return data
}

export const licenceRegister = async (licence) => {
  const {data} = await axios.post(`${API_URL}/licenceRegister/`, licence)
  return data
}

export const searchStudent = async (student) => {
  const {data} = await axios.post(`${API_URL}/searchStudent`, student)
  return data
}

export const uploadcode = async (admin) => {
  const {data} = await axios.post(`${API_URL}/uploadCode/`, admin)
  return data
}

export const unpausedag = async (admin) => {
  const {data} = await axios.post(`${API_URL}/unpauseDag/`, admin)
  return data
}

export const checkstatus = async (admin) => {
  const {data} = await axios.post(`${API_URL}/checkStatus/`, admin)
  return data
}

export const createAzureResources = async (admin) => {
  const {data} = await axios.post(`${API_URL}/createAzureResources/`, admin)
  return data
}

export const releaseAzureResources = async (admin) => {
  const {data} = await axios.post(`${API_URL}/deleteAzureResources/`, admin)
  return data
}
