/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'
import {register, registerUnievrsity} from '../../api'

const initialValues = {
  universityName: '',
  email: '',
  website: '',
  universityId: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  userType: 'university'
}

const registrationSchema = Yup.object().shape({
  universityName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('University name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  website: Yup.string()
    .min(3, 'Minimum 5 symbols')
    .max(200, 'Maximum 50 symbols')
    .matches(/\./, 'Website must contain a dot (.)')
    .required('Website is required'),
  password: Yup.string().required('Password is required'),
  // changepassword: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Password confirmation is required")
  //   .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
})

export function RegisterUniversity() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        registerUnievrsity(values).then(async (res) => {
          if (res.status) {
          }
          alert(res.message)
          setLoading(false)
          window.location.reload()
        })
      } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group universityName */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>University name</label>
        <input
          placeholder='University name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('universityName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.universityName && formik.errors.universityName,
            },
            {
              'is-valid': formik.touched.universityName && !formik.errors.universityName,
            }
          )}
        />
        {formik.touched.universityName && formik.errors.universityName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.universityName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        {/* begin::Form group website */}
        <label className='form-label fw-bolder text-dark fs-6'>University Website</label>
        <input
          placeholder='University website'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('website')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.website && formik.errors.website,
            },
            {
              'is-valid': formik.touched.website && !formik.errors.website,
            }
          )}
        />
        {formik.touched.website && formik.errors.website && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.website}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-success w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
