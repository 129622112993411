import React, { useState } from 'react'
import { uploadcode,unpausedag,checkstatus } from '../../api';

type Props = {}

export default function Lab1({ }: Props) {
    // const [dagName, setDagName] = useState('');
    const [dagData, setDagData] = useState({
        dag_name: '',
        python_code: ''
    })
    const [loading, setLoading] = useState(false);
    // const handleDagNameChange = (e: any) => {
    //     setDagName(e.target.value);
    // };

    const handleDagCreation = (e: any) => {
        e.preventDefault()
        uploadcode(dagData).then((response: any)=>{
            alert(response.data.msg)
        })
    }

    const handleTrigger = (e: any) => {
        e.preventDefault()
        unpausedag(dagData).then((response: any)=>{
            alert(response.data.msg)
        })
    }
    const handleCheckStatus = (e: any) => {
        e.preventDefault()
        checkstatus(dagData).then((response: any)=>{
            alert(response.data.msg)
        })
    }

    return (
        <div>
            <div className="card mb-5 mb-xl-10">
                <form>
                    <div id="kt_account_profile_details" className="collapse show">
                        <form noValidate className="form">
                            <div className="card-body border-top p-9"
                                title='Crete Dag'>
                                <h2>Create Dag</h2>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                                        <span>DAG Name:</span>
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control form-control-lg form-control-solid"
                                        value={dagData.dag_name}
                                        onChange={(e) => { setDagData({ ...dagData, dag_name: e.target.value }) }}
                                    />
                                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                                        <span>Python Code:</span>
                                    </label>
                                    <textarea
                                        className="form-control form-control-lg form-control-solid w-100 mt-5"
                                        rows={10}
                                        name=""
                                        placeholder="Type your answer here"
                                        value={dagData.python_code}
                                        onChange={(e) => { setDagData({ ...dagData, python_code: e.target.value }) }}
                                    ></textarea>
                                    {/* <button
                                        type="submit"
                                        className="btn btn-success w-200px mt-5"
                                        onClick={handleDagCreation}
                                    >
                                        {"Submit"}
                                    </button> */}

                                    <div className="col-lg-8 fv-row">
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={handleDagCreation}
                                    disabled={loading}
                                >
                                    {!loading && "Save"}
                                    {loading && (
                                        <span
                                            className="indicator-progress"
                                            style={{ display: "block" }}
                                        >
                                            Please wait...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                        <br></br>
                    </div>
                </form>
            </div>


            <div className="card mb-5 mb-xl-10">
                <form>
                    <div id="kt_account_profile_details" className="collapse show">
                        <form noValidate className="form">
                            <div className="card-body border-top p-9">
                                <h2>Trigger Dag</h2>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                                        <span>DAG Name:</span>
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control form-control-lg form-control-solid"
                                        value={dagData.dag_name}
                                    />
                                    <div>
                                        <button
                                            className="btn btn-success w-200px mt-5"
                                            onClick={handleTrigger}
                                            >
                                            {"Trigger Dag"}
                                        
                                        </button>
                                    </div>
                                    <div className="col-lg-8 fv-row">
                                    </div>
                                </div>
                            </div>
                        </form>
                        <br></br>
                    </div>
                </form>
            </div>


            <div className="card mb-5 mb-xl-10">
                <form>
                    <div id="kt_account_profile_details" className="collapse show">
                        <form noValidate className="form">
                            <div className="card-body border-top p-9">
                                <h2>Check Status</h2>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                                        <span>DAG Name:</span>
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control form-control-lg form-control-solid"
                                        value={dagData.dag_name}
                                        // onChange={handleDagNameChange}
                                    />
                                    <div>
                                        <button
                                            className="btn btn-success w-200px mt-5"
                                            onClick={handleCheckStatus}>
                                            {"Check Status"}
                                        </button>
                                    </div>
                                    <div className="col-lg-8 fv-row">
                                    </div>
                                </div>
                            </div>
                        </form>
                        <br></br>
                    </div>
                </form>
            </div>
        </div>
    )
}