import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  getLayoutFromLocalStorage,
  ILayout,
  LayoutSetup
} from "../../../_metronic/layout/core";
import { getAllLicences, getAllUniversity } from "../../api";
import { useAuth } from "../../modules/auth";
import { KTIcon } from "../../../_metronic/helpers";
import { RegisterUniversity } from "../../modules/university/RegisterUniversity";
type Props = {};

export default function UniversityPage({}: Props) {
  const [universities, setUniversities] = useState([
    {
      email: "",
      universityId: "",
      universityName: "",
      website: ""
    }
  ]);
  const [tab, setTab] = useState("university");

  useEffect(() => {
    getAllUniversity().then((res) => setUniversities(res.data));
  }, []);

  return (
    <div>
      {/* add university modal */}
      <div className="modal fade" id="kt_modal_add_student" aria-hidden="true">
        <div className="modal-dialog mw-650px">
          <div className="modal-content">
            <div className="modal-header pb-0 border-0 justify-content-between">
              <h2>Add New University</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <hr />
            <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
              <RegisterUniversity />
            </div>
          </div>
        </div>
      </div>

      <div className="card card-custom">
        <div className="card-header card-header-stretch overflow-auto">
          <ul
            className="nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          "
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "university"
                })}
                onClick={() => setTab("university")}
                role="tab"
              >
                Universities
              </a>
            </li>
          </ul>
        </div>

        <form className="form">
          <div className="card-body">
            <div className="tab-content pt-3">
              <div
                className={clsx("tab-pane", { active: tab === "university" })}
              >
                <div className="card-header border-0 pt-6">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-user-table-toolbar="base"
                    >
                      <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_add_student"
                      >
                        <i className="ki-duotone ki-plus fs-2"></i>Add
                        University
                      </button>
                    </div>
                  </div>
                </div>
                <table
                  id="kt_table_users"
                  className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-center"
                  role="table"
                >
                  <thead>
                    <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th
                        role="columnheader"
                        className="min-w-125px text-center"
                        style={{ cursor: "pointer" }}
                      >
                        University Name
                      </th>
                      <th
                        role="columnheader"
                        className="min-w-125px text-center"
                        style={{ cursor: "pointer" }}
                      >
                        University Email
                      </th>
                      <th
                        role="columnheader"
                        className="min-w-125px text-center"
                        style={{ cursor: "pointer" }}
                      >
                        University Website
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-bold" role="rowgroup">
                    {universities.map((item) => (
                      <tr role="row">
                        <td role="cell" className="">
                          <div className="text-gray-800 text-hover-primary mb-1">
                            {item.universityName}{" "}
                          </div>
                        </td>
                        <td
                          role="cell"
                          className="text-gray-800 text-hover-primary mb-1"
                        >
                          {item.email}
                        </td>
                        <td role="cell" className="">
                          <div className="text-gray-800 text-hover-primary mb-1">
                            {item.website}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
