import React from 'react'
import { StatisticsWidget1, StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { Link } from 'react-router-dom'

type Props = {}

export default function LabDashboardWrapper({ }: Props) {
    return (
        <div>
            {/* begin::Row */}
            <div className='row g-5 g-xl-8'>
                <h1>Safera Labs</h1>
                <div className='col-xl-4'>
                    <Link to={"/lab1"}>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-5 mb-xl-8'
                            svgIcon='devices'
                            color='light'
                            iconColor='white'
                            title='Lab 1'
                            titleColor='dark'
                            description='Lorem ipsum is placeholder text commonly used in the graphic'
                            descriptionColor='dark'
                        />

                    </Link>
                </div>
                <div className='col-xl-4'>
                    {/* <Link to={"/documentprocessing/receipts"}> */}
                    <StatisticsWidget5
                        className='card-xl-stretch mb-5 mb-xl-8'
                        svgIcon='notepad'
                        color='light'
                        iconColor='white'
                        title='Lab 2'
                        titleColor='dark'
                        description='Lorem ipsum is placeholder text commonly used in the graphic'
                        descriptionColor='dark'
                    />
                    {/* </Link> */}
                </div>

                <div className='col-xl-4'>
                    {/* <Link to={"/documentprocessing/receipts"}> */}
                    <StatisticsWidget5
                        className='card-xl-stretch mb-5 mb-xl-8'
                        svgIcon='abstract-7'
                        color='light'
                        iconColor='white'
                        title='Lab 3'
                        titleColor='dark'
                        description='Lorem ipsum is placeholder text commonly used in the graphic'
                        descriptionColor='dark'
                    />
                    {/* </Link> */}
                </div>
            </div>
            {/* end::Row */}
        </div>
    )
}