/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { getLayoutFromLocalStorage, ILayout, LayoutSetup } from '../../../_metronic/layout/core'
import { getAllLicences, getAllUniversity, licenceRegister } from '../../api'
import { useAuth } from '../../modules/auth'
import { KTIcon } from '../../../_metronic/helpers'
import { ProfileStats } from '../../modules/profile/ProfileStats'

const LicencesPage: React.FC = () => {
  const { currentUser } = useAuth()

  const [tab, setTab] = useState('Sidebar')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [selectedUniversity, setSelectedUniversity] = useState('')
  const [allLicences, setAllLicences] = useState([
    {
      licenceNumber: '',
      associatedStudentName: '',
      associatedStudentEmail: '',
      status: '',
      universityId: '',
      studentId: '',
      licenceUserName: '',
      licencePassword: '',
    },
  ])

  const [newLicence, setNewLicence] = useState({
    licenceNumber: '',
    universityId: '',
    licenceUserName: '',
    licencePassword: '',
  })

  const [allUniversities, setAllUniversities] = useState([
    {
      universityId: '',
      universityName: '',
    },
  ])

  const handleAddNewLicence = () => {
    licenceRegister(newLicence).then((res) => {
      if (res.status) {
        getAllLicences({ id: selectedUniversity }).then((licences) => {
          setAllLicences(licences.data)
        })
      }
      alert(res.message)
    })
  }

  function generateRandomPassword() {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numericChars = '0123456789';
    const specialChars = '@';

    const allChars = lowercaseChars + uppercaseChars + numericChars + specialChars;

    const getRandomChar = (charSet: any) => charSet[Math.floor(Math.random() * charSet.length)];

    let password = '';

    // Ensure at least one character from each category
    password += getRandomChar(lowercaseChars);
    password += getRandomChar(uppercaseChars);
    password += getRandomChar(numericChars);
    password += getRandomChar(specialChars);

    // Fill the rest of the password randomly up to the maximum length
    const remainingLength = Math.floor(Math.random() * (10 - 4)) + 4;
    for (let i = 0; i < remainingLength; i++) {
      password += getRandomChar(allChars);
    }

    // Shuffle the characters in the password
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password;
  }

  function generateRandomUsername() {
    const prefix = 'sl' + allUniversities.find(obj => obj.universityId == selectedUniversity)?.universityName.toLowerCase() || 'ID not found';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';

    const getRandomChar = (charSet: any) => charSet[Math.floor(Math.random() * charSet.length)];

    let username = prefix;

    // Fill the rest of the username randomly up to the total length
    for (let i = 0; i < 4; i++) {
      username += getRandomChar(lowercaseChars);
    }

    return username;
  }

  const username = generateRandomUsername();

  const handleLicenceGeneration = () => {
    const password = generateRandomPassword()
    const username = generateRandomUsername()
    setNewLicence({ ...newLicence, licenceUserName: username, licencePassword: password })
  }

  useEffect(() => {
    if (selectedUniversity) {

      getAllLicences({ id: selectedUniversity }).then((licences) => {
        setAllLicences(licences.data)
      })
      setNewLicence({ ...newLicence, universityId: selectedUniversity })
    }
  }, [selectedUniversity])

  useEffect(() => {
    getAllUniversity().then((res) => setAllUniversities(res.data))
    getAllLicences({ id: currentUser?.universityId }).then((licences) => {
      setAllLicences(licences.data)
    })
  }, [])

  return (
    <>
      {/* add licence modal */}
      <div className='modal fade' id='kt_modal_add_student' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-between'>
              <h2>Add New Licence</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <hr />
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              {/* <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Licence ID
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    value={newLicence.licenceNumber}
                    onChange={(e) =>
                      setNewLicence({
                        ...newLicence,
                        licenceNumber: e.target.value
                      })
                    }
                  />
                </div>
              </div> */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  University Name
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    value={newLicence.universityId}
                    onChange={(e) =>
                      setNewLicence({
                        ...newLicence,
                        universityId: e.target.value,
                      })
                    }
                  >
                    <option value=''>Select an University...</option>
                    {allUniversities.map((uni) => (
                      <option value={uni.universityId}>{uni.universityName}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Licence Username
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    value={newLicence.licenceUserName}
                    onChange={(e) =>
                      setNewLicence({
                        ...newLicence,
                        licenceUserName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Licence Password
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    value={newLicence.licencePassword}
                    onChange={(e) =>
                      setNewLicence({
                        ...newLicence,
                        licencePassword: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              {/* <button className="btn btn-success">Confirm</button> */}
              <button
                className='btn btn-success'
                onClick={handleAddNewLicence}
                data-bs-dismiss='modal'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {currentUser ? currentUser.user_type == 'super-admin' ? <></> : <ProfileStats /> : ''}

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Sidebar',
                })}
                onClick={() => setTab('Sidebar')}
                role='tab'
              >
                Licences
              </a>
            </li>
          </ul>
        </div>

        <form className='form'>
          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', { active: tab === 'Sidebar' })}>
                {currentUser ? (
                  currentUser.user_type == 'super-admin' ? (
                    <div className='card-header border-0 pt-6'>
                      <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                          <label className='col-lg-6 col-form-label fw-bold fs-6'>
                            Universities
                          </label>
                          <div className='col-lg-12 fv-row'>
                            <select
                              className='form-select form-select-solid form-select-lg fw-bold'
                              onChange={(e) => setSelectedUniversity(e.target.value)}
                            >
                              <option value=''>Select an University...</option>
                              {allUniversities.map((uni) => (
                                <option value={uni.universityId}>{uni.universityName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div
                          className='d-flex justify-content-end'
                          data-kt-user-table-toolbar='base'
                        >
                          <button
                            type='button'
                            className='btn btn-success'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_add_student'
                            onClick={handleLicenceGeneration}
                          >
                            <i className='ki-duotone ki-plus fs-2'></i>Add Licence
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  ''
                )}
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-center'
                  role='table'
                >
                  <thead>
                    <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      {/* <th
                        role="columnheader"
                        className="min-w-125px"
                        style={{ cursor: "pointer" }}
                      >
                        Licence Number
                      </th> */}
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Licence UserName
                      </th>
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Licence Password
                      </th>
                      <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Licence Status
                      </th>
                      {/* <th
                        role='columnheader'
                        className='min-w-125px text-center'
                        style={{ cursor: 'pointer' }}
                      >
                        Action
                      </th> */}
                      {/* <th
                    role="columnheader"
                    className="text-end min-w-100px"
                    style={{ cursor: "pointer" }}
                  >
                    Actions

                  </th> */}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                    {allLicences.map((item) => (
                      <tr role='row'>
                        {/* <td role="cell" className="">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              <a href="#"></a>
                            </div>
                            <div className="d-flex flex-column">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary mb-1"
                              >
                                {item.licenceNumber}
                              </a>
                            </div>
                          </div>
                        </td> */}
                        <td role='cell' className=''>
                          {item.licenceUserName}
                        </td>
                        <td role='cell' className=''>
                          <div className='text-gray-800 text-hover-primary mb-1'>
                            {item.licencePassword}
                          </div>
                        </td>
                        <td role='cell' className=''>
                          <div
                            className={
                              'badge fw-bolder ' +
                              (item.status == 'Assigned' ? 'badge-success' : 'badge-danger')
                            }
                          >
                            {item.status}
                          </div>
                        </td>
                        {/* <td role='cell' className=''>
                          <button className='btn btn-sm btn-success'>Create Azure Resource</button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default LicencesPage
