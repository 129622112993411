/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { ProfileHeader } from '../../modules/profile/ProfileHeader'
import { useAuth } from '../../modules/auth'
import { getAllSuperAdminStats } from '../../api'
import { Link } from 'react-router-dom'
import Lab1 from '../../modules/labs/Lab1'
import { Error404 } from '../../modules/errors/components/Error404'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'

type Props = {
  countUniversity: number
  countAssociatedLicences: number
  countLicences: number
}

const DashboardPage = ({ countUniversity, countAssociatedLicences, countLicences }: Props) => (
  <div>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <Link to={'/university'}>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            description='Total University'
            color='#F1416C'
            stat={countUniversity}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </Link>
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <Link to={'/licences'}>
          <CardsWidget7
            className='h-md-100 mb-5 mb-xl-10'
            description='Total Licences'
            icon={false}
            stats={countLicences}
            labelColor='dark'
            textColor='gray-300'
          />
        </Link>
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <Link to={'/licences'}>
          <CardsWidget7
            className='h-md-100 mb-5 mb-xl-10'
            description='Assosiated Licences'
            icon={false}
            stats={countAssociatedLicences}
            labelColor='dark'
            textColor='gray-300'
          />
        </Link>
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <EngageWidget10 className='h-md-100' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </div>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const [superAdminStats, setSuperAdminStats] = useState({
    countUniversity: 0,
    countLicences: 0,
    countAssociatedLicences: 0,
  })
  useEffect(() => {
    getAllSuperAdminStats().then((res) => {
      setSuperAdminStats(res)
    })
  }, [])
  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      {currentUser ? (
        currentUser.user_type == 'super-admin' ? (
          <DashboardPage
            countUniversity={superAdminStats.countUniversity}
            countAssociatedLicences={superAdminStats.countAssociatedLicences}
            countLicences={superAdminStats.countLicences}
          />
        ) : (currentUser.user_type == 'student' || currentUser.user_type == 'university-student') ? (
          <ErrorsPage />
        ) : (<ProfileHeader />)
      ) : (
        ''
      )}
    </div>
  )
}

export { DashboardWrapper }
